// ** Icon imports


const navigation = () => {
  return [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Services",
      path: "/services",
    },

    {
      title: "IT solutions",
      // path: "/it-solutions",
    },
    {
      title: "Customer Experience",
      path: "/customer-experience",
    },
  ];
};

export default navigation;
