// export const API_BASE_URL = "http://192.168.29.33:6060";
export const API_BASE_URL = "https://api.dw-its.com";
// export const MEDIA_URL = "https://zan-rides-assets.s3.af-south-1.amazonaws.com";

export const ApiEndpoints = {
  CONTACTUS: {
    list: `${API_BASE_URL}/api/admin/contact-us`,
  },
  CAREER: {
    list: `${API_BASE_URL}/api/admin/career`,
  },
  CAREER_DETAILS: {
    list: (id) => `${API_BASE_URL}/api/admin/career/${id}`,
  },

  GET_IN_TOUCH: {
    create: `${API_BASE_URL}/api/get-in-touch`,
  },
  GET_SUPPORT_WITH_US: {
    create: `${API_BASE_URL}/api/get-support-with-us`,
  },
  PERSONALIZED_WEBDEMO: {
    create: `${API_BASE_URL}/api/personalized-web-demo`,
  },
  ADD_PERSONALIZED_QUOTE: {
    create: `${API_BASE_URL}/api/personalized-quote`,
  },
  APPLICATION_FORM: {
    create: `${API_BASE_URL}/api/application-form`,
  },
};
