import { Box, Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ITSolutionDropDown = () => {
  const itSolutions = [
    "Identity and access management",
    "Unified service management",
    "Unified endpoint management and security",
    "IT operations management",
    "Security information and event management",
    "Advanced IT analytics",
    "Low Code App Development",
  ];
  const subItSolutions = [
    {
      title: "Active Directory Management",
      description: "Manage, track, and secure Active Directory.",
    },
    {
      title: "Identity governance and administration",
      description:
        "Orchestrate user identity management and access controls for Zero Trust.",
    },
    {
      title: "Privileged access management",
      description:
        "Control and secure privileged access to critical enterprise systems.",
    },
  ];
  const subItSolutions1 = [
    {
      title: "ADManager Plus",
      description:
        "Active Directory, Microsoft 365, and Exchange management and reporting",
      path: "/it-solution-overview",
    },
    {
      title: "ADAudit Plus",
      description:
        "Real time Active Directory, file and windows server change auditing",
    },
    {
      title: "ADSelfService Plus",
      description: "Identity security with adaptive MFA, SSPR, and SSO",
    },
    {
      title: "Exchange Reporter Plus",
      description:
        "Reporting, auditing, and monitoring for hybrid Exchange and Skype",
    },
    {
      title: "Recovery Manager Plus",
      description:
        "Active Directory, Microsoft 365, and Exchange backup and recovery",
    },
  ];
  const subItSolutions2 = [
    {
      title: "AD360",
      description:
        "Workforce identity and access management for hybrid ecosystems",
    },
    {
      title: "Identity360",
      description: "A cloud-native identity platform for workforce IAM",
    },
    {
      title: "M365 Manager Plus",
      description: "Microsoft 365 management, reporting, and auditing",
    },
  ];
  const subItSolutions3 = [
    {
      title: "PAM360",
      description: "Complete privileged access security for enterprises",
    },
    {
      title: "Password Manager Pro",
      description: "Privileged password management",
    },
    {
      title: "Access Manager Plus",
      description: "Secure remote access and privileged session management",
    },
    {
      title: "Key Manager Plus",
      description: "SSH key and SSL/TLS certificate management",
    },
  ];
  return (
    <>
      <Box sx={{ width: "1280px", px: 10, py: 5 }}>
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ display: "flex", flexDirection: "column", gap: 5 }}
          >
            <Typography
              sx={{
                fontSize: { lg: "24px" },
                lineHeight: { lg: "32px" },
                fontWeight: 600,
                color: "#602DE1",
              }}
            >
              IT Solutions
            </Typography>
            <Box
              sx={{ pl: 5, display: "flex", flexDirection: "column", gap: 7 }}
            >
              {itSolutions.map((item, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: { lg: "16px" },
                    lineHeight: { lg: "24px" },
                    fontWeight: 600,
                    color: "#101828",
                    "&:hover": {
                      color: "#602DE1",
                    },
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid item container xs={8}>
            <Grid item container xs={12} columnSpacing={8}>
              {subItSolutions.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px" },
                      lineHeight: { lg: "24px" },
                      fontWeight: 600,
                      color: "#101828",
                      textWrap: "wrap",
                      "&:hover": {
                        color: "#602DE1",
                      },
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { lg: "14px" },
                      lineHeight: { lg: "20px" },
                      fontWeight: 400,
                      color: "#667085",
                      textWrap: "wrap",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} sx={{ py: 5, color: "#CACACA" }}>
              <Divider />
            </Grid>
            <Grid item container xs={12} columnSpacing={8}>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {subItSolutions1.map((item, index) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={item?.path}
                    key={index}
                  >
                    {" "}
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { lg: "16px" },
                          lineHeight: { lg: "24px" },
                          fontWeight: 500,
                          color: "#101828",
                          textWrap: "wrap",
                          "&:hover": {
                            color: "#602DE1",
                          },
                        }}
                        gutterBottom
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: "14px" },
                          lineHeight: { lg: "20px" },
                          fontWeight: 400,
                          color: "#667085",
                          textWrap: "wrap",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {subItSolutions2.map((item, index) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={item?.path}
                    key={index}
                  >
                    {" "}
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { lg: "16px" },
                          lineHeight: { lg: "24px" },
                          fontWeight: 500,
                          color: "#101828",
                          textWrap: "wrap",
                          "&:hover": {
                            color: "#602DE1",
                          },
                        }}
                        gutterBottom
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: "14px" },
                          lineHeight: { lg: "20px" },
                          fontWeight: 400,
                          color: "#667085",
                          textWrap: "wrap",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {subItSolutions3.map((item, index) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={item?.path}
                    key={index}
                  >
                    {" "}
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { lg: "16px" },
                          lineHeight: { lg: "24px" },
                          fontWeight: 500,
                          color: "#101828",
                          textWrap: "wrap",
                          "&:hover": {
                            color: "#602DE1",
                          },
                        }}
                        gutterBottom
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: "14px" },
                          lineHeight: { lg: "20px" },
                          fontWeight: 400,
                          color: "#667085",
                          textWrap: "wrap",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ITSolutionDropDown;
