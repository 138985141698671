// ** MUI Imports
import Fab from "@mui/material/Fab";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import MuiToolbar from "@mui/material/Toolbar";
// ** Icons Imports
import ArrowUp from "mdi-material-ui/ArrowUp";
// ** Theme Config Import
import themeConfig from "src/configs/themeConfig";

// ** Components
import Customizer from "src/@core/components/customizer";
import Footer from "./components/shared-components/footer";
// import Navigation from "./components/horizontal/navigation";
import Navigation from "./components/horizontal/navigation";
import ScrollToTop from "src/@core/components/scroll-to-top";
import AppBarContent from "./components/horizontal/app-bar-content";

// ** Styled Component
import DatePickerWrapper from "src/@core/styles/libs/react-datepicker";
// import Avatar from '../components/mui/avatar'
import { Box, Button, Typography, useScrollTrigger } from "@mui/material";
// import Logo from '../../assets/images/Logo.png'
import Translations from "src/layouts/components/Translations";
// import Bg from "../../assets/images/bg.png";
import React from "react";

import { Link, useLocation } from "react-router-dom";
// import Logo from '../components/logo'
import { useState } from "react";
import LoginDialog from "src/views/dialog/LoginDialog";
import headerBgImg from "../../assets/images/header-bgImg.png";
import logoImg from "../../assets/images/logo.svg";

const HorizontalLayoutWrapper = styled("div")({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  ...(themeConfig.horizontalMenuAnimation && { overflow: "clip" }),
});

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: "100%",
  padding: `${theme.spacing(0, 6)} !important`,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  // padding: theme.spacing(6),
  // transition: 'padding .25s ease-in-out',
  // [theme.breakpoints.down('sm')]: {
  //   paddingLeft: theme.spacing(4),
  //   paddingRight: theme.spacing(4)
  // }
}));

const HorizontalLayout = (props) => {
  const location = useLocation();
  // ** Props
  const {
    hidden,
    children,
    settings,
    scrollToTop,
    saveSettings,
    horizontalNavMenuContent: userHorizontalNavMenuContent,
  } = props;

  // ** Vars
  const { skin, appBar, navHidden, appBarBlur, contentWidth } = settings;

  function ChangeColorOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      style: {
        // backgroundColor: trigger ? "#fff" : "transparent",
        color: trigger ? "#000" : "transparent",
      },
    });
  }

  const [openDialog, setOpenDailog] = useState(false);
  const handleDialogOpen = () => {
    setOpenDailog(true);
  };
  const handleClose = () => {
    setOpenDailog(false);
  };
  return (
    <HorizontalLayoutWrapper className="layout-wrapper">
      <ChangeColorOnScroll {...props}>
        <AppBar
          color="default"
          // elevation={skin === 'bordered' ? 0 : 3}
          className="layout-navbar-and-nav-container"
          position={appBar === "fixed" ? "sticky" : "static"}
          sx={{
            boxShadow: "none",
            // alignItems: "center",
            color: "text.primary",
            // justifyContent: "center",
            // backgroundImage: `url(${headerBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            // height: "260px",
            height: { lg: "220px", xl: "260px" },
            backgroundColor: "#F5F5F9",

            // clipPath:
            //   'path(" M0,0 L0,156 Q499,104 884,152 T2000,275 L2000,0 Z")',
            clipPath:
              'path(" M0,0 L0,156 Q499,104 884,152 T2000,275 L9000,0 Z")',
          }}
        >
          <Box
            className="layout-navbar"
            // sx={{
            //   // width: "100%",
            //   // bgcolor: "#0C7362",
            //   // borderRadius: "10px",
            //   // height: "41px",
            //   ...(navHidden
            //     ? {}
            //     : {
            //         borderBottom: (theme) =>
            //           `1px solid ${theme.palette.divider}`,
            //       }),
            // }}
            sx={{ px: "65px" }}
          >
            <Toolbar
              className="navbar-content-container"
              // sx={{
              //   mx: "auto",
              //   height: "41px !important",
              //   ...(contentWidth === "boxed" && {
              //     "@media (min-width:1440px)": { maxWidth: 1440 },
              //   }),
              //   minHeight: (theme) =>
              //     `${theme.mixins.toolbar.minHeight - 1}px !important`,
              // }}
            >
              <AppBarContent
                {...props}
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
              />
            </Toolbar>
          </Box>

          {navHidden ? null : (
            <Box
              className="layout-horizontal-nav"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
                px: "100px",
                // paddingLeft: "170px",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                {/* <Logo /> */}
                <img src={logoImg} />
              </Link>
              <Box>
                <Toolbar
                  className="horizontal-nav-content-container"
                  sx={{
                    bgcolor: "primary",
                    mx: "auto",
                    height: "100%",
                    ...(contentWidth === "boxed" && {
                      "@media (min-width:1440px)": { maxWidth: 1440 },
                    }),
                    // minHeight: theme => `${theme.mixins.toolbar.minHeight - (skin === 'bordered' ? 1 : 0)}px !important`
                  }}
                >
                  {(userHorizontalNavMenuContent &&
                    userHorizontalNavMenuContent(props)) || (
                    <Navigation {...props} />
                  )}
                </Toolbar>
              </Box>
              <Box
              // display="flex"
              // flexDirection="row"
              // alignItems="center"
              // gap={3}
              // pr="113px"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // mr: 10,
                  }}
                >
                  <Button
                    variant="contained"
                    as={Link}
                    to="/it-solution-supportUs"
                    sx={{
                      textDecoration: "none",
                      // backgroundColor: "#ffff",
                      "&:hover": {
                        bgcolor: "#825ce0cc",
                      },
                      borderRadius: "16px",
                      padding: "3px 24px",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}

                    // onClick={handleDialogOpen}
                  >
                    Get Support
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </AppBar>
      </ChangeColorOnScroll>

      <ContentWrapper
        className="layout-page-content"
        sx={{
          ...(contentWidth === "boxed" && {
            mx: "auto",
            "@media (min-width:1440px)": { maxWidth: 1440 },
            "@media (min-width:1200px)": { maxWidth: "100%" },
          }),
        }}
      >
        {children}
      </ContentWrapper>

      <Footer {...props} />

      <DatePickerWrapper sx={{ zIndex: 11 }}>
        <Box id="react-datepicker-portal"></Box>
      </DatePickerWrapper>

      {themeConfig.disableCustomizer || hidden ? null : <Customizer />}

      {/* {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )} */}
      <LoginDialog open={openDialog} close={handleClose} />
    </HorizontalLayoutWrapper>
  );
};

export default HorizontalLayout;
