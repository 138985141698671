import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import provideSolutionImg from "../../../assets/images/serviceDropdown1.png";
import implementationImg from "../../../assets/images/serviceDropdown2.png";
import trainingImg from "../../../assets/images/serviceDropdown3.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ServiceDropDown = () => {
  const services = [
    {
      title: "Provide Solutions",
      description:
        "Tailored Manage Engine solutions to meet your unique business needs, enhancing efficiency and productivity with cutting-edge technology. Our experts analyze your requirements and design solutions that align perfectly with your goals. From initial consultation to deployment, we ensure a seamless experience. Trust us to provide innovative solutions that drive success.",
      img: provideSolutionImg,
    },
    {
      title: "Implementation & Configuration",
      description:
        "Seamless implementation and meticulous configuration of Manage Engine products, ensuring optimal performance and integration with your existing systems. Our team handles every detail, from installation to customization, to fit your specific environment. We follow best practices to minimize disruption and maximize effectiveness. Count on us for a smooth transition and robust configuration.",
      img: implementationImg,
    },
    {
      title: "Training & Certification",
      description:
        "Comprehensive training programs and certifications to empower your team with the skills and knowledge needed to maximize Manage Engine capabilities. Our training covers all aspects, from basic operations to advanced features, ensuring your team is proficient and confident. Certification validates their expertise, enhancing their value and your organization’s performance. Invest in our training to achieve mastery and excellence.",
      img: trainingImg,
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = (value) => {
    navigate("/services", { state: { serviceType: value } });
  };
  return (
    <>
      <Box sx={{ width: "1280px", px: 15 }}>
        <Grid container>
          <Grid
            item
            xs={2.5}
            sx={{ display: "flex", flexDirection: "column", gap: 5, py: 3 }}
          >
            <Typography
              sx={{
                fontSize: { lg: "24px" },
                lineHeight: { lg: "32px" },
                fontWeight: 600,
                color: "#602DE1",
              }}
            >
              Services
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 5, pl: 3 }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "16px" },
                  lineHeight: { lg: "24px" },
                  fontWeight: 600,
                  color: "#101828",
                  "&:hover": {
                    color: "#602DE1",
                  },
                }}
                onClick={() => handleNavigate("engine")}
              >
                Manage Engine Services
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "16px" },
                  lineHeight: { lg: "24px" },
                  fontWeight: 600,
                  color: "#101828",
                  "&:hover": {
                    color: "#602DE1",
                  },
                }}
                onClick={() => handleNavigate("zoho")}
              >
                Zoho Services
              </Typography>
            </Box>
            <Box>
              <Link style={{ textDecoration: "none" }} to={"/services"}>
                {" "}
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{
                    fontSize: { xs: "14px", md: "16px" },
                    fontWeight: 600,
                    borderRadius: "141px",
                    padding: "3px 24px",
                  }}
                  endIcon={<ArrowForwardOutlinedIcon />}
                >
                  SEE ALL
                </Button>
              </Link>
            </Box>
          </Grid>
          {services.map((item, index) => (
            <Grid item xs={3.1} key={index}>
              <Card sx={{ boxShadow: "none" }}>
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: 5 }}
                >
                  <Box>
                    <img src={item.img} style={{ borderRadius: "6px" }} />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        color: "#101828",
                        fontWeight: 500,
                      }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    {/* <Tooltip title={item.description} arrow> */}
                    <Typography
                      sx={{
                        textWrap: "wrap",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 4,
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        color: "#667085",
                        fontWeight: 400,
                      }}
                    >
                      {item.description}
                    </Typography>
                    {/* </Tooltip>   */}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ServiceDropDown;
