import React from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const LoginDialog = ({ open, close }) => {
  return (
    <>
      {/* <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth={'sm'}
        PaperProps={{ sx: { paddingY: 10, borderRadius: 0, paddingX: 10, backgroundColor: '#111111' } }}
      >
        <DialogTitle textAlign={'center'}>Who do you want to login as?</DialogTitle>
        <DialogContent>
          <Grid container sx={{ mt: { sm: 10 } }}>
            <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Link to={'https://customer.zanride.com'} style={{ textDecoration: 'none' }}>
                <Button sx={{ display: 'flex', flexDirection: 'column' }}>
                  <img src={userImg} alt='' />
                  <Typography sx={{ mt: { sm: 7 } }}>User</Typography>
                </Button>
              </Link>
            </Grid>
            <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Divider sx={{ borderRightWidth: 2, display: { xs: 'none', sm: 'block' } }} orientation='vertical' />{' '}
            </Grid>
            <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button sx={{ display: 'flex', flexDirection: 'column' }}>
                <img src={driverImg} alt='' />
                <Typography sx={{ mt: { sm: 7 } }}>Driver</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

export default LoginDialog;
